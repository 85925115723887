// JavaScript Document


// $(document).ready(function () {
//   $('.loop.featured_product').owlCarousel({
//     items:2,
//     loop:true,
//     center:true,
//     margin:30,
//     responsive: {
//       0: {
//         items: 2,
//         nav: false,
//         margin:20, center:true,
        
//       },

//       600: {
//         items: 2,
//         nav: false,
//         margin:20, center:true, 
//       },

//       800: {
//         items: 4,

//         nav: false
//       },

//       1000: {
//         items: 4,
//         nav: false,
//         center:false,
//         margin:30,
//       }
//     }
//   })
// })


// $(document).ready(function () {
//   $('.featured_product_lrg').owlCarousel({
    
    
//     items:2,
//     loop:true,
//     margin:30,
//     responsive: {
//       0: {
//         items: 2,

//         nav: false, center: false,
//         margin:10
//       },

//       600: {
//         items: 2,
//         nav: false, center: false,
//       },

//       800: {
        
//         items: 3,

//         nav: false
//       },

//       1000: {
//         items: 3,
//         nav: false,
//       }
//     }
//   })
// })

// $(document).ready(function () {
//   $('.relevant_product').owlCarousel({
    
    
//     items:2,
//     loop:true,
//     margin:30,
//     responsive: {
//       0: {
//         items: 2,

//         nav: false, center: false,
//         margin:10
//       },

//       600: {
//         items: 2,
//         nav: false, center: false,
//       },

//       800: {
        
//         items: 3,

//         nav: false
//       },

//       1000: {
//         items: 3,
//         nav: false,
//       }
//     }
//   })
// })

